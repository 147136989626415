import React from "react";
import { ARTICLE_1 } from "shared/Types/articles";
import { useArticlesData } from "shared/Hooks/Articles/useArticlesData";

import { Hero, Layout, Text } from "components/BlogLayout";

const Article_1 = () => {
  const data = useArticlesData(ARTICLE_1);

  return (
    <Layout>
      <Hero data={data} />
      <Text data={data.text.first} />
      <Text data={data.text.second} />
      <Text data={data.text.third} />
      <Text data={data.text.fourth} />
      <Text data={data.text.fifth} />
    </Layout>
  );
};

export default Article_1;
